const DEV = process.env.NODE_ENV === 'development';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import lightGallery from 'lightgallery';
import lgVideo from 'lightgallery/plugins/video';
import 'lightgallery/css/lightgallery-bundle.css';

import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export default {
	init() {

		// JavaScript to be fired on all pages
		console.log('init common');

		const footer = document.querySelector('footer');

		setTimeout(() => {
			footer.classList.remove('!hidden');
		}, 1000);

		//footer z index
		var isAtBottom = false;
		window.addEventListener('scroll', function () {
			// Altezza totale del documento
			var documentHeight = document.body.scrollHeight;
			// Altezza della finestra del browser
			var windowHeight = window.innerHeight;
			// Posizione dello scroll rispetto alla cima del documento
			var scrollTop = window.scrollY || window.pageYOffset;
			// Controllo se siamo arrivati alla fine della pagina
			if (scrollTop + windowHeight >= documentHeight) {
				// Se siamo già arrivati al fondo e scrolliamo verso l'alto, reimpostiamo il flag
				if (!isAtBottom) {
					isAtBottom = true;
					footer.classList.remove('-z-1');
				}
			} else {
				// Se non siamo più al fondo, reimpostiamo il flag
				isAtBottom = false;
				footer.classList.add('-z-1');
			}
		});

		// Remove no-js
		document.documentElement.classList.remove('no-js');
		document.documentElement.classList.add('js');

		// Test touch
		if (!('ontouchstart' in document.documentElement)) {
			document.documentElement.classList.add('no-touch');
		} else {
			document.documentElement.classList.add('touch');
		}

		// AT NAVIGATION
		const body = document.querySelector('body');
		const header = document.querySelector('#masthead');
		const menu = document.querySelector('#at-menu');
		const menuLogo = document.querySelector('#at-menu-logo');
		const languageSwitcher = document.querySelector('#at-language-switcher');

		const hamburger = document.querySelector('#at-hamburger');
		const nav = document.querySelector('#at-nav');

		const aboveTheFold = document.querySelector('.at_atf');		
				

		hamburger.addEventListener('click', toggleMenu);

		function toggleMenu() {
			body.classList.toggle('overflow-hidden');

			header.classList.toggle('h-full');
			header.classList.toggle('altezzaMenu');

			hamburger.classList.toggle('rotate-45')

			nav.classList.toggle('hidden');
			nav.classList.toggle('fade-nav');

			let aboveTheFoldPosition = aboveTheFold.getBoundingClientRect().top;
			if(aboveTheFoldPosition >= 0 && aboveTheFoldPosition > -header.offsetHeight) {
				// gestione menu in hp dove i colori sono invertiti
				if(body.classList.contains('home')){

					if(menuLogo.querySelector('svg').style.fill == 'black'){
						menuLogo.querySelector('svg').style.fill = 'white';
					}
					else {
						menuLogo.querySelector('svg').style.fill = 'black';
					}

					menu.classList.toggle('bg-white');

					languageSwitcher.classList.toggle('text-white');

					//ciclo su tutti i figli del languageSwitcher
					var childrenSwitcher = Array.from(languageSwitcher.children);
					childrenSwitcher.forEach((child) => {
						if(child.classList.contains('border-white') || child.classList.contains('border-black' ) ){
							child.classList.toggle('border-white');
							child.classList.toggle('border-black');
						}
						if(child.classList.contains('bg-white') || child.classList.contains('bg-black') ){
							child.classList.toggle('bg-white');
							child.classList.toggle('bg-black');
						}
					});

					//ciclo su tutti i figli dell'hamburger
					var childrenHamburger = Array.from(hamburger.children);
					childrenHamburger.forEach((child) => {
						if(child.classList.contains('bg-white') || child.classList.contains('bg-black') ){
							child.classList.toggle('bg-white');
							child.classList.toggle('bg-black');
						}
					});
					
					
				}
			}

			if(body.classList.contains('home')){
				menu.classList.toggle('border-b');
				menu.classList.toggle('border-black');
			}
			
		}
		

		// AT CUSTOM ANIMATION
		

		// Utilizzare GSAP per animare lo slide
		gsap.registerPlugin(ScrollTrigger);

		const projectGallery = document.querySelector(
			'#at-project-gallery-container'
		);

		const projectGalleryCol1 = document.querySelector(
			'#at-project-gallery-1'
		);
		const projectGalleryCol2 = document.querySelector(
			'#at-project-gallery-2'
		);
		const projectGalleryCol3 = document.querySelector(
			'#at-project-gallery-3'
		);

		if (projectGallery) {
			let projectGalleryTl = gsap.timeline({
				scrollTrigger: {
					trigger: projectGallery,
					start: 'top bottom',
					end: 'top center',
					scrub: 1,
				},
			});
			if (
				projectGalleryCol1 &&
				projectGalleryCol2 &&
				projectGalleryCol3
			) {
				projectGalleryTl.to(
					[
						projectGalleryCol1,
						projectGalleryCol2,
						projectGalleryCol3,
					],
					{
						y: '0%',
						duration: 1,
						ease: 'none',
					}
				);
			}
		}

		console.log('step 3');

		//Video lightbox
		document.querySelectorAll('.js-video-lightbox').forEach((item, i) => {
			item.id = 'gallery-' + i;
			lightGallery(document.getElementById('gallery-' + i), {
				selector: 'this',
				plugins: [lgVideo],
				download: false,
				counter: false,
			});
		});

		const videos = document.querySelectorAll('.video-js');
    	const playButtons = document.querySelectorAll('.play-button');

		const players = [];

    	videos.forEach((video, index) => {

			players[index] = videojs(video.id, {
				controls: false, // Nascondi i controlli di Video.js
				autoplay: false,
				preload: 'auto',
				fluid: true,
				responsive: true,
				fill: true,
			});

			const playButton = playButtons[index];

			playButton.addEventListener('click', function () {
				for (let i = 0; i < players.length; i++) {
					if (i !== index) {
						players[i].pause();
					}
					else {
						players[i].play();
					}
				}
				playButton.style.display = 'none';
			});

			players[index].on('pause', function () {
				playButton.style.display = 'flex';
			});

			players[index].on('ended', function () {
				playButton.style.display = 'flex';
			});

		});
		
		// AT FOOTER
		jQuery(document).ready(function ($) {
			function updateFooterFullpage() {
				if ($('#fullpage').length) {
					var $footer = $('#fullpage_footer.real-footer'),
						$fixedFooter = $('#fullpage_footer.clone-footer');
					$fixedFooter.html($footer.html());
				}
			}
			updateFooterFullpage();
		});

	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
		console.log('finalize common');
	},
};
