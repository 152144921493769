// Optional. When using scrollOverflow:true
// Importing fullpage.js
//import fullpage from 'fullpage.js'

require('fullpage.js/dist/fullpage.css');
// require('../util/fullpage.parallax.min.js')
import fullpage from 'fullpage.js/dist/fullpage.extensions.min';
import { gsap } from 'gsap';

export default {
	init() {
		console.log('progetti');

		const footer = document.querySelector('footer');
		//footer.classList.add('!hidden');

		const body = document.querySelector('body');
		// filterBtn = document.querySelector('#at-filter-project-btn');

		// if (filterBtn) {
		// 	filterBtn.addEventListener('click', function () {
		// 		body.classList.toggle('filter-menu--open');
		// 	});
		// }

		jQuery(document).ready(function ($) {
			let filterBtns = $('.js-filter-projects'),
				loadMoreBtn = $('#loadMoreBtn'),
				noPostsMsg = $('#noPostsMsg'),
				projectsContainer = $('#at-project-gallery-container'),
				$footer = $('#fullpage_footer'),
				$fixedFooter = $footer.clone(),
				paged = 1,
				taxonomies = {
					project_category: [],
					media_type: [],
				};

			//manageFooter();
			
			//FILTERS
			filterBtns.bind('click', function (e) {
				e.preventDefault();
				paged = 1;
				var $this = $(this);
				let catID = $this.attr('data-cat');
				$this.toggleClass('activeProjectFilter'); //toggle class on clicked filter
				
				filterBtns.not($this).removeClass('activeProjectFilter'); //remove class from all other filters

				if(catID == 'all') {
					taxonomies.project_category = [];
				}
				else {
					taxonomies.project_category = [catID];
				}

				JSON.stringify(taxonomies);

				loadPosts();
			});

			loadMoreBtn.bind('click', function (e) {
				e.preventDefault();
				paged++;
				loadPosts();
			});

			function loadPosts() {
				projectsContainer.addClass('loading');
				noPostsMsg.hide();

				$.ajax({
					type: 'POST',
					url: '/wp-admin/admin-ajax.php',
					dataType: 'json',
					data: {
						action: 'projects_filter',
						taxonomies: taxonomies,
						paged: paged,
					},
					success: function (res) {

						console.log(res.taxonomies)

						if (res.posts) {
							if (paged == 1) {
								projectsContainer.html(res.posts);
							} 
							else {
								projectsContainer.append(res.posts);
							}
							
							if (res.maxPages > paged && loadMoreBtn.length) {
								loadMoreBtn.show();
							} 
							else {
								loadMoreBtn.hide();
							}
						
							fullpage_api.reBuild();
						}

						if (res.posts.length == 0) {
							projectsContainer.html('');
							noPostsMsg.show();
							loadMoreBtn.hide();
						}

						projectsContainer.removeClass('loading');
					},
					fail: function (err) {
						console.log('There was an error: ' + err);
						projectsContainer.removeClass('loading');
					},
				});
			}

			//FULLPAGE
			new fullpage('#fullpage', {
				licenseKey: 'MWCWH-TS6JK-A6NJ9-82WK9-KEJAL',
				credits: { enabled: false },
				//scrollOverflow: false,
				scrollHorizontallyKey:
					'bmxjM1JuTFdGMFptRmpkRzl5ZVM1amIyMD14bl9VNThjMk55YjJ4c1NHOXlhWHB2Ym5SaGJHeDVVNTA=', //see https://goo.gl/xkUmHS
				responsiveWidth: 768,
				normalScrollElements: '#at-filter-project-menu, #masthead',
				lazyLoading: true,
				onLeave: function (origin, destination, direction, trigger) {
					var leavingSection = this;
					if (origin.index == 0 && direction == 'down') {
						//body.classList.add('at-hide-menu')
						gsap.to('#projectsContent', {
							duration: 0.5,
							//delay: 0.5,
							backgroundColor: '#ffffff',
						});
						gsap.to('#projectsContent .project_thumb', {
							duration: 0.5,
							delay: 0.5,
							y: 0,
						});
						gsap.to(
							[
								'.filter_bar',
								'#at-project-gallery-container .leftRow',
							],
							{
								duration: 0.5,
								delay: 0.5,
								opacity: 1,
							}
						);
					}
				},
				beforeLeave: function (
					origin,
					destination,
					direction,
					trigger
				) {
					// if (origin.index == 1) {
					// 	direction == 'down'
					// 		? $fixedFooter.addClass('visible')
					// 		: $fixedFooter.removeClass('visible');
					// }
				},
				afterRender: function () {
					//footer.classList.remove('!hidden');
				},

			});

			function manageFooter() {
				$footer.addClass('real-footer');
				$fixedFooter.addClass('clone-footer');
				$('#fullpage').after($fixedFooter);
			}
		});
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
